import React from 'react'
import PropTypes from 'prop-types'
import Navbar from '../Navbar'
import './styles.css'

const Layout = ({ children }) => (
  <>
    <Navbar />
    <div className="layout">
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500|Nunito:300,400&display=swap" rel="stylesheet"/>
      {children}
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
