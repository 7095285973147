import React, { useState } from 'react'
import { Link } from 'gatsby'
import {
  DrupalAuthenticationProvider,
  DrupalAuthentication,
} from 'react-drupal'
import { GlobalClient } from 'drupal-jsonapi-client'

import '../Layout/styles.css'
import './styles.css'

const Navbar = () => {
  const [authenticated, setAuthenticated] = useState(false)

  const authenticateMainLinks = [
    <Link key="1" className="navbar__link" to="/how-it-works">
      How it works
    </Link>,
    <Link key="5" className="navbar__link" to="/admin/voyage-dashboard">
      Voyage Dashboard
    </Link>,
    <Link key="9" className="navbar__link" to="/blogs">
      Blogs
    </Link>,
  ]

  const authenticatedUtilityLinks = [
    <Link key="6" className="navbar__utility-nav__link" to="/admin/profile">
      Edit Profile
    </Link>,
    <Link
      key="7"
      className="navbar__utility-nav__link"
      to="/admin/pending-requests"
    >
      Pending Requests
    </Link>,
    <a
      key="8"
      className="navbar__utility-nav__link"
      href="/"
      onClick={e => {
        e.preventDefault()
        DrupalAuthentication.clearSession()
        GlobalClient.authorization = null
      }}
    >
      Logout
    </a>,
  ]

  const notAuthenticatedMainLinks = [
    <Link key="1" className="navbar__link" to="/how-it-works">
      How it works
    </Link>,
    <Link key="3" className="navbar__link" to="/admin">
      Voyage Dashboard
    </Link>,
    <Link key="9" className="navbar__link" to="/blogs">
      Blogs
    </Link>,
  ]

  const notAuthenticatedUtilityLinks = [
    <Link key="4" className="navbar__utility-nav__link" to="/admin">
      Login
    </Link>,
  ]

  return (
    <div className="navbar__wrapper">
      <div className="navbar">
        <Link to="/" className="navbar__logo-wrapper">
          <img alt="Sailboat" className="navbar__logo" src="/sailboat.svg" />
          <div className="navbar__logo-text">Skill Sailors</div>
        </Link>
        <div className="navbar__links">
          <DrupalAuthenticationProvider onInit={() => setAuthenticated(true)}>
            {({ jwt }) =>
              authenticated ? (
                jwt ? (
                  <React.Fragment>
                    {authenticateMainLinks}
                    {authenticatedUtilityLinks}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {notAuthenticatedMainLinks}
                    {notAuthenticatedUtilityLinks}
                  </React.Fragment>
                )
              ) : null
            }
          </DrupalAuthenticationProvider>
        </div>
        <div id="navbar-hamburger">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="navbar-hamburger__links">
            <DrupalAuthenticationProvider>
              {({ jwt }) =>
                authenticated ? (
                  jwt ? (
                    <React.Fragment>
                      <div className="navbar__hamburger-menu-section">
                        {authenticateMainLinks}
                      </div>
                      <div className="navbar__hamburger-menu-section--utility">
                        {authenticatedUtilityLinks}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="navbar__hamburger-menu-section">
                        {notAuthenticatedMainLinks}
                      </div>
                      <div className="navbar__hamburger-menu-section--utility">
                        {notAuthenticatedUtilityLinks}
                      </div>
                    </React.Fragment>
                  )
                ) : null
              }
            </DrupalAuthenticationProvider>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
