import React from 'react'
import { Title, Meta, Link } from 'react-head'

const Seo = ({ title = '', meta = [], links = [] }) => {
  return (
    <>
      <Title>{title.length > 0 ? `${title} | ` : ''}Skill Sailors</Title>
      {meta.map(({ name, content }) => {
        return <Meta name={name} content={content} key={name} />
      })}
      {links.map((link, index) => {
        return <Link key={index} {...link} />
      })}
    </>
  )
}

export default Seo
